import React from "react"
import PropTypes from "prop-types"
import { Container, Col, Row } from "react-bootstrap"

const Welcome = ({ siteTitle }) => (
    <section id="welcome">
        <Container>
            <Row className='center-flex'>
                <Col className='scrollDown center-flex' xs='12' sm='12' md='7' lg='7'>
                    <h3>Book an appointment with {siteTitle} today for a new tattoo.</h3>
                </Col>
                <Col className='center-flex' xs='12' sm='12' md='5' lg='5'>
                    <button className='btn btn-secondary center-block'><a href='/contact/'>Contact Us</a></button>
                </Col>
            </Row>
        </Container>
    </section>
)

Welcome.propTypes = {
    siteTitle: PropTypes.string.isRequired
}

Welcome.defaultProps = {
    siteTitle: ``
}

export default Welcome