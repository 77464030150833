import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import { confirmAlert } from 'react-confirm-alert'
import Layout from '../components/layout'
import Banner from '../components/banner'
import Seo from '../components/seo'
import Welcome from '../components/welcome'

import '../../node_modules/react-confirm-alert/src/react-confirm-alert.css'

import PortfolioSeoImage from '../assets/images/bkgd-skull.jpg'

import tat01 from '../assets/images/tattoos/ball-chain.jpg'
import tat02 from '../assets/images/tattoos/bigfoot.jpg'
import tat03 from '../assets/images/tattoos/circle-sun-pattern.jpg'
import tat04 from '../assets/images/tattoos/cool-cat-can.jpg'
import tat05 from '../assets/images/tattoos/dagger-snake.jpg'
import tat06 from '../assets/images/tattoos/dice-skull-jaw.jpg'
import tat07 from '../assets/images/tattoos/dragon.jpg'
import tat08 from '../assets/images/tattoos/face-sword.jpg'
import tat09 from '../assets/images/tattoos/ghost-moon.jpg'
import tat10 from '../assets/images/tattoos/hand-snake-bite.jpg'
import tat11 from '../assets/images/tattoos/hippie-reaper.jpg'
import tat12 from '../assets/images/tattoos/la-morte-card.jpg'
import tat13 from '../assets/images/tattoos/lighthouse-octopus.jpg'
import tat14 from '../assets/images/tattoos/lion-roar.jpg'
import tat15 from '../assets/images/tattoos/lucifer.jpg'
import tat16 from '../assets/images/tattoos/moth-skull.jpg'
import tat17 from '../assets/images/tattoos/mystic-eye.jpg'
import tat18 from '../assets/images/tattoos/pin-up-temptation.jpg'
import tat19 from '../assets/images/tattoos/pirate-ship.jpg'
import tat20 from '../assets/images/tattoos/plague-doctor.jpg'
import tat21 from '../assets/images/tattoos/reaper.jpg'
import tat22 from '../assets/images/tattoos/reaper-gun.jpg'
import tat23 from '../assets/images/tattoos/rose-sword.jpg'
import tat24 from '../assets/images/tattoos/skewered-snake.jpg'
import tat25 from '../assets/images/tattoos/skull-arrows.jpg'
import tat26 from '../assets/images/tattoos/skull-knife.jpg'
import tat27 from '../assets/images/tattoos/skull-mask.jpg'
import tat28 from '../assets/images/tattoos/skull-mohawk.jpg'
import tat29 from '../assets/images/tattoos/snakes-back.jpg'
import tat30 from '../assets/images/tattoos/sparrow-hearts.jpg'
import tat31 from '../assets/images/tattoos/vacation-reaper.jpg'
import tat32 from '../assets/images/tattoos/virgin-mary.jpg'
import tat33 from '../assets/images/tattoos/wolf-chest.jpg'
import tat34 from '../assets/images/tattoos/wolf-roses.jpg'
import tat35 from '../assets/images/tattoos/woman-skull-embrace.jpg'
import tat36 from '../assets/images/tattoos/woman-snakes-skulls.jpg'

const imgList = [tat01,tat02,tat03,tat04,tat05,tat06,tat07,tat08,tat09,tat10,tat11,tat12,tat13,tat14,tat15,tat16,tat17,tat18,tat19,tat20,tat21,tat22,tat23,tat24,tat25,tat26,tat27,tat28,tat29,tat30,tat31,tat32,tat33,tat34,tat35,tat36]

function view(e,img) {
    e.preventDefault()
    confirmAlert({
        childrenElement: () => <img className='img-responsive' alt='tattoo' src={img} />,
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
            {
                label: 'Close',
                onClick: () => ''
            }
        ]
    })
}

class Portfolio extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            totalView: 9,
            totalImages: 36
        }

        this.setView = this.setView.bind(this)
    }
    
    setView() {
        this.setState(state => ({
            totalView: state.totalView + 9
        }))
    }
    
    render() {
        return (
            <StaticQuery
            query={graphql`
                query PartnersMeta {
                    site {
                        siteMetadata {
                            title
                            description
                            google {
                                googleMapAPIKey
                                googleReCaptchaKey
                            }
                        }
                    }
                }
            `}
            render={data => (
                <>
                <Layout page='ink'>
                    <Seo title={`The Ink | ${data.site.siteMetadata.title}`} description={data.site.siteMetadata.description} image={PortfolioSeoImage} keywords={[``]} />
                    <Banner image={PortfolioSeoImage} title='The Ink' subTitle='' titleColor='#FFFFFF' subTitleColor='#FFFFFF' />
                    <section>
                        <Container>
                            <Row>
                                <Col className='center-flex' xs='12' sm='12' md='12' lg='12'>
                                    {imgList.slice(0,this.state.totalView).map((x, i) => {
                                        return <Col className='panel' xs='12' sm='12' md='4' lg='4'>
                                            <div key={i} className='panel col-11' role='button' onClick={(e) => view(e,x)} onKeyDown={(e) => view(e,x)} tabIndex='0'>
                                                <img className='img-responsive' src={x} alt='tattoo example' />
                                            </div>
                                        </Col>
                                    })}
                                    {this.state.totalView !== this.state.totalImages && <button className="btn btn-primary" onClick={() => this.setView()}>{this.state.isLoading ? 'Loading...' : 'Load More'}</button>}
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <Welcome siteTitle={data.site.siteMetadata.title} />
                </Layout>
                </>
            )}
            />
        )
    }
}

export default Portfolio